import React, { useState } from "react"

import ChevronDown from "../../images/icons/chevron-down.svg"
import { Link } from "gatsby"

const MobileFilters = ({
  headerText = "Filter les articles",
  allCategoriesText = "Tout",
  classname,
  categories,
  base,
  categoryBase,
  rangeLongDescription,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {!!rangeLongDescription && (
        <aside className="mobile-filters__range">
          <p className="description">{rangeLongDescription}</p>
        </aside>
      )}
      <section className={`mobile-filters ${classname ? classname : ""}`}>
        <button
          className={"mobile-filters__button"}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{headerText}</span>
          <img src={ChevronDown} className={isOpen ? "is-open" : ""} alt="" />
        </button>
        <ul className={`mobile-filters__items ${isOpen ? "is-open" : ""}`}>
          <li className={"mobile-filters__item"}>
            <Link
              to={base}
              title={allCategoriesText}
              className={"mobile-filters__link"}
            >
              {allCategoriesText}
            </Link>
          </li>
          {categories.map(({ name, slug }, i) => (
            <li key={i} className={"mobile-filters__item"}>
              <Link
                to={
                  categoryBase ? `${categoryBase}/${slug}` : `${base}/${slug}`
                }
                title={name}
                className={"mobile-filters__link"}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </>
  )
}

export default MobileFilters
