import Layout from "~/components/layout/layout"
import React from "react"
import SEO from "~/components/seo.js"
import Shop from "~/components/shop/shop.js"
import { graphql } from 'gatsby';
import _ from 'lodash'

const ShopArchivePage = ({ data, pageContext, location }) => {
  const { products, ranges, base, name } = pageContext
  const seo = data.page.seo;

  // console.log({pageContext});

  let flatProducts = _.flatten(products);

  // console.log({flatProducts})

  // Ordering par gamme
  // for (let item of flatProducts) {
  //   let ranges = item.product_ranges.nodes;
  //   if(ranges.length > 1){
  //     let bigger = ranges[0];
  //     for(let range of ranges){
  //       if(range.gamme_content.order > bigger.gamme_content.order) bigger = range;
  //     }
  //     item.order = bigger.gamme_content.order;
  //   }
  //   else item.order = ranges[0].gamme_content.order;
  // }

  // const orderedProductsList = _.orderBy(flatProducts, ['order'], ['asc']);

  // Ordering par la propriete productPosition
  const orderedProductsList = _.orderBy(flatProducts, ['position'], ['asc']);

  // console.log({flatProducts});
  // console.log({orderedProductsList});

  return (
    <Layout location={location}>
      {/* <SEO
        title={seo && seo.title ? seo.title : 'Funky Veggie, categories produits'}
        description={seo && seo.metaDesc ? seo.metaDesc : 'Découvrez nos produits'}
      /> */}
      <SEO meta={seo} />
      <Shop products={orderedProductsList} ranges={ranges} base={base} name={name} />
    </Layout>
  )
}

export const query = graphql`
  query ShopArchivePage {
    page: wpPage(slug: {eq: "shop"}) {
      seo {
        title
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphImage {
          localFile {
            url
          }
        }
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
      }
    }
  }
`

export default ShopArchivePage
