import { Helmet } from "react-helmet"
import MobileFilters from "~/components/mobile-filters/mobile-filters.js"
import Product from "~/components/shop/product/product.js"
import React from "react"
import StickySidebar from "~/components/sticky-sidebar/sticky-sidebar.js"
import useWindowSize from "~/helpers/hooks/useWindowSize.js"

const Shop = ({ products, ranges, base, rangeName, rangeLongDescription }) => {
  const sizes = useWindowSize()
  const url = typeof window !== 'undefined' ? window.location.href : '';

// console.log(products);
  let tab = [];
  let i = 1;
  products.map((product) => {
    // if(!!product?.image?.sourceUrl) console.info('Image manquante: ', product.image.sourceUrl, ' -> ', product?.name + ' - ' + product?.variantName);
    // console.log(product);
    let item = {
      "@type": "ListItem",
      "position": i,
      "item": {
        "@type": "Product",
        "name": product.name + ' - ' + product.variantName,
        "url" : "https://funkyveggie.fr"+product.uri,
        "image": (product?.image?.sourceUrl || '').replace('https://funky.buddybuddy.io/wp-content/uploads/', 'https://cdn.funkyveggie.fr/wp-content/uploads/'),
        "description": product.seo.metaDesc,
        "sku": product.shopifyVariants[0].sku,
        "offers" : {
          "@type" : "Offer",
          "priceCurrency" : "EUR",
          "price" : product.shopifyVariants[0].price, 
        }
      }
    }
    i++;
    tab.push(item);
  })

  return (
    <div className={"container allow-overflow"}>
      
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "ItemList",
              "name": "${rangeName || 'Tous les produits'}",
              "url": "${url}",
              "description": "${rangeLongDescription}",
              "itemListElement": ${JSON.stringify(tab)}
            }
          `}
        </script> 
      </Helmet>

      <div className={"page-shop"}>
        {!sizes.isMobile && (
          <StickySidebar
            base={base}
            allCategoriesText={"Tous les produits"}
            classname={"page-shop__aside"}
            categories={ranges}
            rangeName={rangeName}
            rangeLongDescription={rangeLongDescription}
          />
        )}
        <section className={"page-shop__main"}>
          <h1 className={"page-shop__title"}>{rangeName ? rangeName : 'Nos produits'}</h1>
          {sizes.isMobile && (
            <MobileFilters
              base={base}
              headerText={"Voir la liste"}
              allCategoriesText={"Tous les produits"}
              categories={ranges}
              rangeName={rangeName}
              rangeLongDescription={rangeLongDescription}
            />
          )}
          <section className="shop-product-list">
            {products.length > 0 ? (
              products.map((product, i) => {
                return <Product {...product} key={i} index={i} />
              })
            ) : (
              <p style={{ textAlign: "center", width: "100%" }}>
                Aucun résultat
              </p>
            )}
          </section>
        </section>
      </div>
    </div>
  )
}

export default Shop
