import { Link } from "gatsby"
import React from "react"
import { OptionDuTheme } from "../../hooks/site-options"

const StickySidebar = ({
  headerText,
  allCategoriesText,
  classname,
  categories,
  base,
  categoryBase,
  rangeName,
  rangeLongDescription,
  hideDescription,
}) => {
  const options = OptionDuTheme();
  return (
    <section className={`sticky-sidebar ${classname}`}>
      <div className="sticky-sidebar__container">
        {!!headerText && (
          <div className={"sticky-sidebar__title"}>{headerText}</div>
        )}
        <ul className={"sticky-sidebar__items"}>
          <li className={"sticky-sidebar__item"}>
            <Link
              to={base}
              title={allCategoriesText}
              className={"sticky-sidebar__link"}
            >
              {allCategoriesText}
            </Link>
          </li>
          {categories.map(({ name, slug, gamme_content }, i) => (
            <li key={i} className={"sticky-sidebar__item"}>
              <Link
                to={
                  categoryBase ? `${categoryBase}/${slug}` : `${base}/${slug}`
                }
                title={name}
                className={"sticky-sidebar__link"}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
        {!!rangeLongDescription && !!rangeName && (
          <aside
            className="page-shop__aside-block"
            style={{ backgroundColor: "#ff6b00" }}
          >
            <h2 className="title">{rangeName}</h2>
            {!!rangeLongDescription && 
              <p className="description">{rangeLongDescription}</p>
            }
          </aside>
        )}
        {!rangeLongDescription && !rangeName && !hideDescription &&
          <aside
            className="page-shop__aside-block"
            style={{ backgroundColor: "#ff6b00" }}
          >
            <h2 className="title">{allCategoriesText}</h2>
            <p className="description">{options.optionsDuTheme.options_content.categories.descAllproducts}</p>
          </aside>
        }
      </div>
    </section>
  )
}

StickySidebar.propTypes = {
  hideDescription: false,
};

export default StickySidebar
